import React from "react";
import importedComponent from "react-imported-component";
import language from "../../newLanguage.json";
import { Switch, Route } from "react-router-dom";
import jQuery from "jquery";
import ReactPixel from "react-facebook-pixel";

/* Styles */
import "./Main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Main.scss";

/* Modules */
const Homepage = importedComponent(() =>
  import("../../Container/Homepage/Homepage")
);
const AnnouncementList = importedComponent(() =>
  import("../../Container/AnnouncementList/AnnouncementList")
);
const AnnouncementDetails = importedComponent(() =>
  import("../../Container/AnnouncementDetails/AnnouncementDetails")
);
const CampaignDetails = importedComponent(() =>
  import("../../Container/CampaignDetails/CampaignDetails")
);
const CampaignList = importedComponent(() =>
  import("../../Container/CampaignList/CampaignList")
);
const Subpage = importedComponent(() =>
  import("../../Container/Subpage/Subpage")
);
const Brochure = importedComponent(() =>
  import("../../Container/Brochure/Brochure")
);
const MapLocation = importedComponent(() => import("../../Container/Map/Map"));
const PriceList = importedComponent(() =>
  import("../../Container/PriceList/PriceList")
);
const Search = importedComponent(() => import("../../Container/Search/Search"));

const GetRecall = importedComponent(() =>
  import("../../Container/Recall/Recall")
);
//const CommunicationPermissions = importedComponent(() => import("../../Container/CommunicationPermissions/CommunicationPermissions"));
const Error404 = importedComponent(() =>
  import("../../Container/ErrorPage/Error404")
);

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

export default class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      languageDetails: null,
    };
  }


  pathControl = () => {
    const langEn = language["en"];
    const langTr = language["tr"];
    const element = document.getElementsByTagName("body")[0];
    const splitUrl = window.location.pathname.split("/");
    if (window.location.pathname === "/" + langTr[6].mainurl.title[5] || window.location.pathname === "/" + langEn[6].mainurl.title[5] || window.location.pathname === "/" + langEn[6].mainurl.title[9]) {
      element.classList.add("Map");
      if (window.location.pathname === "/" + langTr[6].mainurl.title[5]) {
        localStorage.setItem("lang", "tr");
      } else {
        localStorage.setItem("lang", "en");
      }
    } else {
      element.classList.remove("Map");
    }
    if (localStorage.getItem("lang") === "tr") {
      for (let i = 1; i < 6; i++) {
        if (langEn[i]?.link.split("/")[1] === splitUrl[1] && i !== 4) {
          localStorage.setItem("lang", "en");
        }
      }
    }
    if (localStorage.getItem("lang") === "en") {
      for (let i = 1; i < 6; i++) {
        if (langTr[i]?.link.split("/")[1] === splitUrl[1] && i !== 4) {
          localStorage.setItem("lang", "tr");
        }
      }
    }
    if (splitUrl[1] == "kariyer") {
      document.getElementById('trCareerLink').click()
    }
    if (splitUrl[1] == "career") {
      document.getElementById('enCareerLink').click()
    }
  };
  componentWillMount = () => { };
  componentDidMount = () => {
    let activePath = window.location.pathname;
    const upperCaseControl = activePath !== activePath.toLocaleLowerCase();
    if (upperCaseControl) {
      activePath = activePath.toLocaleLowerCase();
      window.location.href = activePath;
    }
    function showImages(el) {
      var windowHeight = jQuery(window).height();

      jQuery(el).each(function () {
        var thisPos = jQuery(this).offset().top;

        var topOfWindow = jQuery(window).scrollTop();
        if (topOfWindow + windowHeight - 200 > thisPos) {
          jQuery(this).addClass("animate__fadeInRight");
        }
      });
    }

    // if the image in the window of browser when the page is loaded, show that image
    jQuery(document).ready(function () {
      showImages(".star");

      jQuery("html").attr("lang", localStorage.lang ?? "tr");
      jQuery("#closeCookie").on("click", function () {
        localStorage.cookie = false;

        jQuery(".accept-cookie").addClass("d-none");
      });
    });

    // if the image in the window of browser when scrolling the page, show that image
    jQuery(window).scroll(function () {
      showImages(".star");
    });

    this.pathControl();
  };
  componentDidUpdate = (prevProps, prevState) => {
    this.pathControl();
    this.checkFbPixel();
  };

  checkFbPixel() {
    const FbPixel = localStorage.getItem("fb");
    if (FbPixel === "true") {
      ReactPixel.init('705117961454574', options);
      ReactPixel.pageView(); // For tracking page view
    }
  }

  render() {
    const lang = language[localStorage.lang ?? "tr"];
    const langEn = language["en"];
    const langTr = language["tr"];
    return (
      <div className="App show-on-scroll main-container">
        <a href="https://careers.mercedesbenzturk.com.tr/?locale=tr_TR" id="trCareerLink" style={{ display: 'none' }} > </a>
        <a href="https://careers.mercedesbenzturk.com.tr/?locale=en_US" id="enCareerLink" style={{ display: 'none' }} > </a>
        {lang !== undefined ? (
          <Switch>
            <Route path="/" exact component={Homepage} />
            <Route
              path={[langEn[5].link + "/:counter?", langTr[5].link + "/:counter?"]}
              component={AnnouncementList}
            />
            <Route
              path={
                [
                  "/" +
                  langEn[6].mainurl.title[0] +
                  "/" +
                  langEn[6].mainurl.title[1] +
                  "/:pagename",
                  "/" +
                  langTr[6].mainurl.title[0] +
                  "/" +
                  langTr[6].mainurl.title[1] +
                  "/:pagename",
                ]
              }
              component={AnnouncementDetails}
            />
            <Route
              path={[langEn[2].sub[0].sub[0].sub[2].link, langTr[2].sub[0].sub[0].sub[2].link]}
              component={Brochure}
            />
            <Route
              path={[langEn[2].sub[1].sub[0].sub[2].link, langTr[2].sub[1].sub[0].sub[2].link]}
              component={Brochure}
            />
            <Route
              path={["/" + langEn[6].mainurl.title[2] + "/:pagename", "/" + langTr[6].mainurl.title[2] + "/:pagename"]}
              component={CampaignList}
            />
            <Route
              path={["/" + langEn[6].mainurl.title[3] + "/:pagename/:subname", "/" + langTr[6].mainurl.title[3] + "/:pagename/:subname"]}
              component={CampaignDetails}
            />
            <Route
              path={[langEn[1].link + "/:pagename?/:subpage?/:subdetails?", langTr[1].link + "/:pagename?/:subpage?/:subdetails?"]}
              component={Subpage}
            />
            <Route
              path={[langEn[2].link + "/:pagename?/:subpage?/:subdetails?", langTr[2].link + "/:pagename?/:subpage?/:subdetails?"]}
              component={Subpage}
            />
            <Route
              path={[langEn[3].link + "/:pagename?/:subpage?/:subdetails?", langTr[3].link + "/:pagename?/:subpage?/:subdetails?"]}
              component={Subpage}
            />
            <Route
              path={[langEn[4].link + "/:pagename?/:subpage?/:subdetails?", langTr[4].link + "/:pagename?/:subpage?/:subdetails?"]}
              component={Subpage}
            />
            <Route
              path={["/kariyer", "/career"]}
            >
            </Route>
            <Route
              path={["/" + langEn[6].mainurl.title[5], "/" + langTr[6].mainurl.title[5]]}
              exact
              component={MapLocation}
            />
            <Route
              path={["/" + langEn[6].mainurl.title[6] + "&=:searchText", "/" + langTr[6].mainurl.title[6] + "&=:searchText"]}
              component={Search}
            />
            <Route
              path={[langEn[2].sub[0].sub[0].sub[3].link, langTr[2].sub[0].sub[0].sub[3].link]}
              component={PriceList}
            />
            <Route
              path={[langEn[2].sub[1].sub[0].sub[3].link, langTr[2].sub[1].sub[0].sub[3].link]}
              component={PriceList}
            />
            <Route
              path={"/kamyon/gonullu-geri-cagirma"}
              component={GetRecall}
            />
            <Route
              path={"/otobus/gonullu-geri-cagirma"}
              component={GetRecall}
            />
            <Route path={"/truck/voluntary-recall"} component={GetRecall} />
            <Route path={"/bus/voluntary-recall"} component={GetRecall} />
            <Route
              path={"*"}
              component={Error404}
              status={404}
            />
          </Switch>
        ) : (
          ""
        )}
      </div>
    );
  }
}
